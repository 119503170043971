// Variables
$icon-size: 30px;
$animation-duration: 8s;
$white-color: #ffffff;  // Assuming this is your white color variable
$dark-blue-text: #1a1a1a;  // Assuming this is your dark blue text color

// Icon colors
$js-color: #F7DF1E; // JavaScript yellow
$html-color: #E34F26; // HTML orange
$css-color: #1572B6; // CSS blue
$react-color: #61DAFB; // React cyan
$github-color: #333; // GitHub dark gray

// Mixin for floating animation
@mixin float($delay, $top, $left) {
  top: $top;
  left: $left;
  animation-delay: $delay;
}

// Container styling
#hero {
  min-height: 100vh;
  height: 100vh;
  display: flex;
  align-items: center;
  border-bottom: 0;
  background: $white-color;
  font-weight: 400;
  color: $dark-blue-text;
  padding: 0rem 5.6rem;
  margin-bottom: 0;
  position: relative;
  overflow: hidden;

  // Uncomment these if you want to set a background image
  /* background: url("/src/assets/[your-image].png");
     background-position: center;
     background-size: cover; */

  @include respond(phone) {
    padding: 0rem $default-font-size;
  }

  .hero-title {
    font-size: 5.6rem;
    font-weight: 700;
    margin-bottom: 3.2rem;
    text-align: left;

    @include respond(tab-land) {
      font-size: 4rem;
    }

    @include respond(tab-port) {
      font-size: 3.6rem;
      text-align: center;
    }

    @include respond(phone) {
      font-size: 3.5rem;
      line-height: 1.5;
    }

    @include respond(phone-xs) {
      font-size: 2.8rem;
    }
  }

  .hero-cta {
    display: flex;

    @include respond(tab-port) {
      justify-content: center;
    }

    a {
      font-size: 2.4rem;

      @include respond(phone) {
        font-size: 2rem;
      }
    }
  }

  .floating-icons {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    i {
      position: absolute;
      font-size: $icon-size;
      animation: float $animation-duration ease-in-out infinite;

      // Set specific colors for each icon
      &:nth-child(1) {
        color: $html-color; // HTML icon color
        @include float(0s, 20%, 10%);
      }

      &:nth-child(2) {
        color: $css-color; // CSS icon color
        @include float(2s, 40%, 80%);
      }

      &:nth-child(3) {
        color: $js-color; // JavaScript icon color
        @include float(4s, 60%, 80%); // Move further to the right
      }

      &:nth-child(4) {
        color: $react-color; // React icon color
        @include float(6s, 80%, 20%);
      }

      &:nth-child(5) {
        color: $github-color; // GitHub icon color
        @include float(8s, 10%, 90%);
      }
    }
  }
}

// Floating keyframes animation
@keyframes float {
  0% {
    transform: translateY(0) translateX(0) rotate(0deg);
  }
  50% {
    transform: translateY(-20px) translateX(20px) rotate(10deg);
  }
  100% {
    transform: translateY(0) translateX(0) rotate(0deg);
  }
}
// Variables
$icon-size: 30px;
$animation-duration: 6s; // Shortened for faster movements
$white-color: #ffffff; // Assuming this is your white color variable
$dark-blue-text: #1a1a1a; // Assuming this is your dark blue text color

// Icon colors
$js-color: #F7DF1E; // JavaScript yellow
$html-color: #E34F26; // HTML orange
$css-color: #1572B6; // CSS blue
$react-color: #61DAFB; // React cyan
$github-color: #333; // GitHub dark gray

// Mixin for floating animation
@mixin float($animation-name, $delay) {
  animation: $animation-name $animation-duration ease-in-out infinite;
  animation-delay: $delay;
}

// Container styling
#hero {
  min-height: 100vh;
  height: 100vh;
  display: flex;
  align-items: center;
  border-bottom: 0;
  background: $white-color;
  font-weight: 400;
  color: $dark-blue-text;
  padding: 0rem 5.6rem;
  margin-bottom: 0;
  position: relative;
  overflow: hidden;

  .floating-icons {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    i {
      position: absolute;
      font-size: $icon-size;

      // Set specific colors for each icon
      &:nth-child(1) {
        color: $html-color; // HTML icon color
        @include float(float1, 0s);
      }

      &:nth-child(2) {
        color: $css-color; // CSS icon color
        @include float(float2, 1s);
      }

      &:nth-child(3) {
        color: $js-color; // JavaScript icon color
        @include float(float3, 2s);
      }

      &:nth-child(4) {
        color: $react-color; // React icon color
        @include float(float4, 3s);
      }

      &:nth-child(5) {
        color: $github-color; // GitHub icon color
        @include float(float5, 4s);
      }
    }
  }
}

// Define keyframes for animations
@keyframes float1 {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(30px, -20px) rotate(5deg);
  }
  50% {
    transform: translate(20px, -40px) rotate(-5deg);
  }
  75% {
    transform: translate(10px, -20px) rotate(5deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes float2 {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(-30px, -10px) rotate(-3deg);
  }
  50% {
    transform: translate(-20px, -30px) rotate(3deg);
  }
  75% {
    transform: translate(-10px, -20px) rotate(-3deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes float3 {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(20px, -25px) rotate(4deg);
  }
  50% {
    transform: translate(30px, -35px) rotate(-4deg);
  }
  75% {
    transform: translate(15px, -20px) rotate(4deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes float4 {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(-20px, -15px) rotate(-2deg);
  }
  50% {
    transform: translate(-25px, -30px) rotate(2deg);
  }
  75% {
    transform: translate(-15px, -10px) rotate(-2deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes float5 {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(10px, -5px) rotate(1deg);
  }
  50% {
    transform: translate(5px, -15px) rotate(-1deg);
  }
  75% {
    transform: translate(10px, -10px) rotate(1deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}
