#skills {
  background-color: $white-color; // White background for contrast
  color: $dark-blue-text; // Dark blue text color
  padding-top: 10rem; // Padding at the top
  padding-bottom: 15rem; // Padding at the bottom for spacing

  @include respond(tab-land) {
    padding-top: 10rem; // Adjust padding for larger screens
  }

  @include respond(phone) {
    padding-top: 8rem; // Adjust padding for mobile devices
  }

  .project-wrapper { // Keeping the original class name for compatibility
    margin-bottom: 15rem; // Margin below the wrapper

    @include respond(phone) {
      margin-bottom: 10rem; // Reduced margin for mobile devices
    }

    .row {
      margin-bottom: 8rem; // Margin below each row

      @include respond(phone) {
        margin-bottom: 4rem; // Margin for mobile devices
      }
    }

    &__text { // Keeping the original class name
      text-align: center; // Centering text for better presentation

      &-title {
        font-weight: bold; // Bold font for titles
        margin-bottom: 1rem; // Space below the title
        font-size: $mid-font-size; // Set font size for mid-level

        @include respond(phone) {
          font-size: 1.8rem; // Adjust font size for mobile
        }
      }
    }

    &__image { // Keeping the original class name
      width: 100%; // Full width for images

      & .thumbnail {
        border: none; // No border on thumbnails
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Soft shadow for depth
        transition: transform 0.3s ease, box-shadow 0.3s ease; // Smooth transitions

        &:hover {
          transform: scale(1.05); // Slight scaling on hover
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); // Enhanced shadow on hover
        }

        @include respond(phone) {
          margin-bottom: 3.2rem; // Space below on mobile
        }
      }
    }
  }
}
